import { HTMLAttributes } from 'react';
import clsx from 'clsx';
import { Variant } from 'shared/types/product';
import ReviewStars from '../review/ReviewStars';

interface VariantCardReviewsProp extends HTMLAttributes<HTMLDivElement> {
  variant: Variant;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default function VariantCardReviews({ className, variant, ...props }: VariantCardReviewsProp): JSX.Element {
  return (
    <div className={clsx('flex items-center text-10 text-grey-5', className)} {...props}>
      {/* todo: placeholder */}
      <ReviewStars stars={3.5} className="fill-grey-5" justify="left" />
      <span className="ml-1 text-10 leading-3">(23)</span>
    </div>
  );
}
