import { useState } from 'react';
import { FNCVariant } from 'shared/types/product/Product';
import Swatch from './swatch';

interface SwatchesProps {
  variants: FNCVariant[];
  activeVariant: FNCVariant;
  onSelect: (variant: FNCVariant) => void;
}

export default function Swatches({ variants, activeVariant, onSelect }: SwatchesProps): JSX.Element {
  const displayLimit = 5;
  const visibleVariants = variants?.slice(0, displayLimit);
  const remainingVariants = variants?.slice(displayLimit);
  const remainingCount = remainingVariants?.length;
  const [toggleAllVariants, setToggleAllVariants] = useState<boolean>(false);

  return (
    <div className="flex flex-wrap">
      {visibleVariants.map((variant) => (
        <Swatch key={variant.key} variant={variant} activeVariantSku={activeVariant.sku} onClick={onSelect} />
      ))}

      {toggleAllVariants &&
        remainingVariants.map((variant) => (
          <Swatch key={variant.key} variant={variant} activeVariantSku={activeVariant.sku} onClick={onSelect} />
        ))}

      {!toggleAllVariants && remainingCount > 0 && (
        <button
          onClick={() => setToggleAllVariants(!toggleAllVariants)}
          className="box-content flex aspect-square items-center  border border-transparent p-0.5 hover:cursor-pointer"
        >
          <div className="flex h-[20px] w-[20px] items-center rounded-full border-0.5 border-grey-4">
            <span className="mx-auto text-10 leading-4 text-grey-5">+{remainingCount}</span>
          </div>
        </button>
      )}
    </div>
  );
}
