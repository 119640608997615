import { useId, InputHTMLAttributes } from 'react';
import { MdCheck } from 'react-icons/md';

interface FNCCheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
}

export default function FNCCheckbox({ className, label, ...props }: FNCCheckboxProps): JSX.Element {
  const id = useId();

  return (
    <div className={className}>
      <div className="flex items-center gap-x-2">
        <div className="relative flex">
          <input
            className="peer relative h-[22px] w-[22px] appearance-none rounded-sm border border-solid border-grey-9 bg-white"
            data-testid="input"
            id={id}
            type="checkbox"
            {...props}
          />
          <MdCheck className="surface-green-2 fade-in-initial peer-checked:fade-in-enter pointer-events-none absolute z-10 h-[22px] w-[22px] rounded-sm fill-white text-22" />
        </div>

        <label className="text-14 text-grey-5" htmlFor={id} data-testid="label">
          {label}
        </label>
      </div>
    </div>
  );
}
