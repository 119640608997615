import { useContext } from 'react';
import { FNCGetColourOptionVariantsPayload, FNCColourOptionVariantsPerSku } from 'shared/types/furniturechoice/product';
import { Attributes } from 'shared/types/product';
import { FNCProduct } from 'shared/types/product/Product';
import { SWRResponse } from 'swr';
import useSWRImmutable from 'swr/immutable';
import { sdk } from 'sdk';
import { ProgressIndicatorContext } from '~/helpers/contexts/furniturechoice/progress-indicator';

export default function useColourOptionVariants(products: FNCProduct[]): SWRResponse<FNCColourOptionVariantsPerSku> {
  const { executeWithProgress } = useContext(ProgressIndicatorContext);
  const key = products
    .map((product) => product.variants[0].sku)
    .sort()
    .join(',');

  return useSWRImmutable<FNCColourOptionVariantsPerSku>(
    `/action/furniturechoice-product/getColourOptionVariants/${key}`,
    async () => {
      return executeWithProgress<FNCColourOptionVariantsPerSku>(async () => {
        const validProducts: FNCGetColourOptionVariantsPayload['products'] = [];

        products.forEach((product) => {
          const masterVariant = product.variants[0];
          const masterVariantAttributes = masterVariant.attributes as Attributes;

          if (masterVariantAttributes.baseid_options) {
            const optionAttributes: Attributes = {};

            Object.keys(masterVariantAttributes).forEach((key) => {
              if (key.startsWith('options_')) {
                optionAttributes[key] = masterVariantAttributes[key];
              }
            });

            validProducts.push({
              masterVariant: {
                sku: masterVariant.sku,
                attributes: optionAttributes,
              },
              baseId: masterVariantAttributes.baseid_options,
              options: product.options,
            });
          }
        });

        const response = await sdk.callAction<FNCColourOptionVariantsPerSku>({
          actionName: 'furniturechoice-product/getColourOptionVariants',
          payload: {
            products: validProducts,
          },
        });

        if (response.isError) {
          throw response.error;
        }

        return response.data;
      });
    },
  );
}
