import { useState, useEffect } from 'react';
import { usePathname } from 'next/navigation';

const usePathFilter = (): string[] => {
  const pathname = usePathname();
  const [filterValues, setFilterValues] = useState<string[]>([]);

  useEffect(() => {
    const pathSegments = pathname.split('/').filter((segment) => segment !== '');
    const fIndex = pathSegments.indexOf('f');
    if (fIndex !== -1) {
      setFilterValues(pathSegments.slice(fIndex + 1));
    }
  }, [pathname]);

  return filterValues;
};

export default usePathFilter;
