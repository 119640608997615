import { useState } from 'react';
import Link from 'next/link';
import { useParams } from 'next/navigation';
import clsx from 'clsx';
import { FNCProduct, FNCVariant } from 'shared/types/product/Product';
import useProductMainImage from '~/helpers/hooks/furniturechoice/use-product-main-image';
import Swatches from './swatches';
import ContentfulImage from '../contentful-image';
import DynamicTag from '../dynamic-tag';
import VariantCardPricing from '../variant-card-pricing';
import VariantCardReviews from '../variant-card-reviews';
// import VariantCardStockStatus from '../variant-card-stock-status';
import VariantSticker from '../variant-sticker';

export type imageSizingType = {
  colSpanPercent?: number;
  colSpanPercentSm?: number;
  colSpanPercentMd?: number;
  colSpanPercentLg?: number;
  colSpanPercentXl?: number;
  colSpanPercent2Xl?: number;
};

interface ProductCardProps {
  headlineAs?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  product: FNCProduct;
  imageSizing: imageSizingType;
  colourOptionVariants?: FNCVariant[];
  isColourOptionVariantsLoading?: boolean;
}

export default function ProductCard({
  headlineAs = 'h2',
  product,
  imageSizing,
  colourOptionVariants = [],
  isColourOptionVariantsLoading,
}: ProductCardProps) {
  const [activeVariant, setActiveVariant] = useState<FNCVariant>(product.selectedVariant);
  const { data } = useProductMainImage({ sku: activeVariant.sku, defaultImage: activeVariant.metafields.assets?.[0] });
  const activeUrl = `/p/${activeVariant.slug}`;

  const { locale } = useParams();

  return (
    <div className="flex flex-col gap-2">
      <Link className="relative" href={activeUrl}>
        <VariantSticker size="small" variant={activeVariant} className="-left-2.5 -top-2.5" />

        <div className={clsx('flex aspect-square overflow-hidden rounded', { 'bg-grey-1': data })}>
          <ContentfulImage className="w-full object-contain" src={data?.uri} alt={data?.alt} {...imageSizing} />
        </div>
      </Link>

      {isColourOptionVariantsLoading && (
        <div className="flex flex-wrap gap-x-1 p-0.5">
          <div className="h-[20px] w-[20px] animate-pulse items-center rounded-full bg-skeleton" />
          <div className="h-[20px] w-[20px] animate-pulse items-center rounded-full bg-skeleton" />
          <div className="h-[20px] w-[20px] animate-pulse items-center rounded-full bg-skeleton" />
          <div className="h-[20px] w-[20px] animate-pulse items-center rounded-full bg-skeleton" />
          <div className="h-[20px] w-[20px] animate-pulse items-center rounded-full bg-skeleton" />
        </div>
      )}

      {!isColourOptionVariantsLoading && (
        <Swatches variants={colourOptionVariants} activeVariant={activeVariant} onSelect={setActiveVariant} />
      )}

      <Link className="flex flex-col gap-y-2" href={activeUrl}>
        <div className="flex flex-col gap-y-1">
          <DynamicTag className="font-playfair-display text-20 font-normal leading-5" as={headlineAs}>
            {activeVariant.attributes?.['descriptors_alt-title']}
          </DynamicTag>

          <p className="line-clamp-2 text-12 leading-3.5">{activeVariant.metafields.productName}</p>
        </div>

        <VariantCardReviews variant={activeVariant} />
        <VariantCardPricing className="mt-3" variant={activeVariant} locale={locale} />
        {/* Commented out until regression is fixed */}
        {/* <StockStatus variant={activeVariant} /> */}
      </Link>
    </div>
  );
}
