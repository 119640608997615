import { HTMLAttributes } from 'react';
import clsx from 'clsx';
import { Variant } from 'shared/types/product';
import { CurrencyHelpers } from '~/helpers/currencyHelpers';

interface VariantCardPricingProp extends HTMLAttributes<HTMLDivElement> {
  variant: Variant;
  locale: string;
}

export default function VariantCardPricing({ variant, locale, ...props }: VariantCardPricingProp): JSX.Element {
  return (
    <div {...props}>
      <span
        data-testid="current-price"
        className={clsx('mr-1 text-16 font-medium leading-5', {
          'text-red-1': variant.discountedPrice || variant.attributes?.['descriptors_is-clearance'],
        })}
      >
        {CurrencyHelpers.formatForCurrency(variant.discountedPrice || variant.price || 0, locale).replace(',', '')}
      </span>

      {variant.discountedPrice && (
        <span className="text-12 text-grey-5 line-through" data-testid="compare-at-price">
          {CurrencyHelpers.formatForCurrency(variant.price ?? 0, locale)}
        </span>
      )}
    </div>
  );
}
