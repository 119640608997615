'use client';

import { HTMLAttributes } from 'react';
import { useParams } from 'next/navigation';
import clsx from 'clsx';
import { Money, Variant } from 'shared/types/product';
import { CurrencyHelpers } from '~/helpers/currencyHelpers';
import { useFormat } from '~/helpers/hooks/useFormat';

export interface VariantStickerProp extends HTMLAttributes<HTMLSpanElement> {
  variant: Variant;
  size?: 'small' | 'medium';
  locale?: string;
}

type StickerConfig = {
  type: 'discount' | 'salmon' | null;
  text: string;
};

export default function VariantSticker({ variant, size, className, ...props }: VariantStickerProp): JSX.Element {
  const { formatMessage } = useFormat({ name: 'furniturechoice' });
  const { locale } = useParams();
  const { attributes } = variant;

  let stickerConfig: StickerConfig = {
    type: null,
    text: '',
  };

  if (attributes?.['descriptors_is-clearance']) {
    stickerConfig = { type: 'discount', text: formatMessage({ id: 'components.variant-sticker.limited_stock' }) };
  } else if (attributes?.['descriptors_is-electric']) {
    stickerConfig = { type: 'salmon', text: formatMessage({ id: 'components.variant-sticker.electric' }) };
  } else if (variant.discountedPrice) {
    const priceDiscount = createPriceLabel(variant, locale);
    stickerConfig = {
      type: 'discount',
      text: formatMessage({ id: 'components.variant-sticker.discount_off', values: { amount: priceDiscount } }),
    };
  } else if (attributes?.['descriptors_is-bestseller']) {
    stickerConfig = { type: 'salmon', text: formatMessage({ id: 'components.variant-sticker.bestseller' }) };
  } else if (
    variant.availableQuantity &&
    attributes?.['inventory_low-threshold'] &&
    variant.availableQuantity <= attributes?.['inventory_low-threshold']
  ) {
    stickerConfig = { type: 'discount', text: formatMessage({ id: 'components.variant-sticker.limited_stock' }) };
  }

  function createPriceLabel(variant: Variant, locale: string) {
    const priceCentAmount = variant.price?.centAmount || 0;
    const discountCentAmount = variant.discountedPrice?.centAmount || 0;
    const diff: Money = {
      ...variant.discountedPrice,
      centAmount: priceCentAmount - discountCentAmount,
    };

    return CurrencyHelpers.formatForCurrency(diff, locale).split('.')[0];
  }

  return (
    <span
      data-testid="variant-sticker"
      className={clsx(
        'absolute z-10 flex items-center justify-center rounded-full p-1 text-center font-medium',
        { 'h-10 w-10 py-1 text-9 leading-2': size === 'small' },
        { 'h-[53px] w-[53px] py-2 text-13 leading-3': size === 'medium' },
        { 'surface-red-1': stickerConfig.type === 'discount' },
        { 'surface-salmon-4': stickerConfig.type === 'salmon' },
        className,
      )}
      {...props}
    >
      {stickerConfig.text}
    </span>
  );
}
